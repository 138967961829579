
.footer_category_details_page{
    margin-top: 130px !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

@media screen and (max-width: 900px){
    .footer_category_details_page{
        margin-top: 175px !important;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
}
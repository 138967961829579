.product_cat_wise_main{
    margin-top: 134px !important;
}

  .side_bar_scroller{
    overflow-y: auto;
    padding-right: 1px !important;
  }

  .cat_container_main{
    padding-left: 1px !important;
  }

  .side_bar_scroller::-webkit-scrollbar {
    width: 6px;
    height: 120px;
  }
   
  .side_bar_scroller::-webkit-scrollbar-track {
    background-color: white;
  }
   
  .side_bar_scroller::-webkit-scrollbar-thumb {
    border-radius: 20px;   
    background-color: gray;
    border: 3px solid gray;
  }

  .category_heading p {
    font-weight: 700;
    margin-left: 12px !important;
  }

  .dropdown_custom_menu{
    overflow-y: scroll;
    overflow-x: scroll;
    padding-left: 0px;
    width: 180px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    right: 0px;
    top: 30px;
  }

  .dropdown_custom_menu li .dropdown_active_border{
    border-bottom: 4px solid green;
  }

  .sortBy_list{
    width: 200px;
    height: 30px;
    border-radius: 4px;
    padding-top: 2px;
  }

  .sortBy_list_custom_select{
    z-index: 10 !important;
    top: 29px !important;
    width: 200px;
    right: 0;
    border: 0.5px solid lightgray;
  }

  .sortBy_list_custom_select li input{
    border-radius: 50% !important;
  }

  .round {
    position: relative;
  }
  
  .round label {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%;
    cursor: pointer;
    height: 12px;
    left: 5px;
    position: absolute;
    top: 8px;
    width: 12px;
  }
  
  .round label:after {
    border: 2px solid rgb(247, 244, 244);
    border-top: none;
    border-right: none;
    content: "";
    height: 4px;
    left: 2.5px;
    opacity: 0;
    position: absolute;
    top: 2.5px;
    transform: rotate(-45deg);
    width: 6px;
  }
  
  .round input[type="checkbox"] {
    visibility: hidden;
  }
  
  .round input[type="checkbox"]:checked + label {
    background-color: #66bb6a;
    border-color: #66bb6a;
  }
  
  .round input[type="checkbox"]:checked + label:after {
    opacity: 1;
  }

  @media screen and (max-width: 900px){
    .product_cat_wise_main{
      margin-top: 141px !important;
  }
  
}

  @media screen and (max-width: 900px){
    .sortBy_list{
      width: 123px;
      height: 30px;
      border-radius: 4px;
      padding-top: 2px;
    }
    .side_bar_scroller{
      height: 460px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 1px !important;
    }
    .cat_container_main{
      height: 460px;
      overflow-y: auto;
      overflow-x: hidden;
      padding-right: 1px !important;
    }
  }

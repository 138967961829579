.category_parent{
    min-height: 270px;
    height: auto;
    display: flex;
    flex-wrap:wrap;
    /* justify-content:space-between; */
}

.category_parent .category_card{
    width: 127.5px !important;
    height: 130px;
}

.category_parent .category_card .product{
    width: 105px !important;
    height: 120px !important;
    border-radius: 10px;
}

@media screen and (max-width : 900px){

    .category_parent .category_card{
        width: 131px !important;
    }
}

@media screen and (max-width : 600px){

    .category_parent .category_card{
        width: 114px !important;
        margin-inline-end: 10px;
    }
}

.category_list::-webkit-scrollbar {
    height: 10px;
}

/* Track */
.category_list::-webkit-scrollbar-track {
    height: 10px;
}

/* Handle */
.category_list::-webkit-scrollbar-thumb {
    background: transparent;
    height: 10px;
}
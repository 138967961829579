.sideBar{
    height: 712px;
}

.sideBar .sub_bars.active_sideBar{
    border-left: 4px solid #0984e3;
}

.sideBar .sub_bars{
    padding: 12px 0;
    border: 1px solid lightgray;
    border-bottom: 0px;
}

.sideBar .sub_bars:hover{
    background-color: #74baff47;
}

.sideBar .sidebar_image{
    height: 50px;
    width: 50px;
    border-radius: 10px;
}

.font_13{
    font-size: 13px;
}

.cat_name{
    width: 200px;
}

@media screen and (max-width:600px){
    .cat_name{
        width: 100%;
        text-align: center;
        /* word-break: break-all; */
        justify-content: center;
    }
    .sideBar .sub_bars{
        padding: 10px;
        border: 1px solid lightgray;
        border-bottom: 0px;
    }
}
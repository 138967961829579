.product_main_image .magnify_image_product{
    position: absolute !important;
    z-index : 100 !important;
  }
  
  .card_add_btn_preset{
    border: 1px solid green ;
    font-size: 20px !important;
    width: 134px;
  }

  .bottom_multi_images .react-multi-carousel-list .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
      right:0 !important;
      position: absolute ;
      background:white !important;
      color: black !important;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .bottom_multi_images .react-multi-carousel-list{
    justify-content: center;
  }

  .bottom_multi_images .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item{
      display:flex;
      justify-content:center;
  }

  .bottom_multi_images .react-multi-carousel-list .react-multiple-carousel__arrow--left{
      background:white !important;
      left:0 !important;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      color: black !important;
  }

  .bottom_multi_images .react-multi-carousel-list .react-multiple-carousel__arrow::before{
      color: black !important;
      font-weight:500 !important;
  }
    
    .product_main_image{
      display:flex ;
      justify-content: center;

      height:550px ;
      /* width: 667px ; */
    }

    #style-5::-webkit-scrollbar-track
    {
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
      background-color: #F5F5F5;
    }
    
    #style-5::-webkit-scrollbar
    {
      width: 10px;
      background-color: #F5F5F5;
    }
    
    #style-5::-webkit-scrollbar-thumb
    {
      background-color: #0ae;
      
      background-image: -webkit-gradient(linear, 0 0, 0 100%,
                         color-stop(.5, rgba(255, 255, 255, .2)),
                 color-stop(.5, transparent), to(transparent));
    }
    



    .bottom_multi_images .bottom_image{
      height: 80px;
      width: 80px;
    }

    .bottom_multi_images .bottom_image img{
      height:100% ;
    }

    .responsive_product_page_carousel .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item{
        width: 716px !important;
    }
    .bottom_multi_images{
      display: flex;
      justify-content: center;
    }
    /* .bottom_multi_images .react-multi-carousel-list
    {
      width: 90%;
    } */
    .variants_options > span
    {
      background: #F3BABF;
      color: #000;
      font-weight: 500;
      border-radius: 8px;
      padding: 4px 6px;
      margin-right: 5px;
    }
    .variants_options .active
    {
      background: #70151d;
      color: #fff;
      font-weight: 600;
    }
  
    .see-all-btn{
      cursor: pointer;
      line-height: 32px;
    color: rgb(12, 131, 31);
    font-family: 'Lato', sans-serif !important;
    font-weight: 600 !important;
    font-size: 15px !important;
    }

    .all-about-product {
      flex: 4 1 0%;
      position: sticky;
      top: 90px;
      bottom: 0px;
      align-self: flex-start;
      padding-top: 4rem;
      padding-left: 3rem;
    }
    .bottom_multi_images .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item
    {
      max-width:90px;
      min-width:90px;
    }
    /* .react-multi-carousel-item{
      max-width:100px;
      min-width:100px;
    } */
    
    @media screen and (max-width:900px){
      .all-about-product {
      flex: 4 1 0%;
      top: 90px;
      bottom: 0px;
      align-self: flex-start;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      margin: 50px 15px; 
      padding: 20px;
      border-radius:8px;  
    }

    .card_add_btn_preset{
      width:130px ;
    }
    .product_page_responsive_carousel_img{
      display: block ;
      margin: 20px 0 ;
      width: 100%;
      padding :  0 60px 0 75px ;
      background-size: cover ;
      background-position: center ;
    } 

  }

    @media screen and (max-width:600px){
        .responsive_product_page_carousel .react-multi-carousel-list .react-multi-carousel-track .react-multi-carousel-item{
            width: 306px !important;
        }
        .all-about-product{
            width: 92% !important;
        }

        .product_page_responsive_carousel_img{
          display: block ;
          margin: 20px 0 ;
          width: 100%;
          padding :  0 72px 0 52px ;
          background-size: cover ;
          background-position: center ;
        } 
    }



.acount_Dropdown{
  width:250px;
  z-index:100000;
  top:50px;
  position:absolute !important;
  border-radius:0 0 15px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.header_mobile_logo img{
  width:45px !important;
}

@media screen and (max-width:900px){
    .acount_Dropdown{
      width:200px;
      z-index:100000;
      top:50px;
      right:10px ;
      border-radius:0 0 15px 15px;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    }
    .suggestion_Container .product_list {
      height: 50px;
    }
}

[title]:after{
  background: #333;
}

[title]:hover:after{
  background: #333;
}